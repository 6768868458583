import React from "react";
import Menu from "../../components/Home/Menu";
import Meteo from "../../components/Home/Meteo";

const Home = () => {
  return (
    <div className="container">
      <Meteo />
      <Menu />
      <iframe
        className="iframeHome"
        width="100"
        height="100"
        title="address"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2558.8979312234924!2d1.4509393156101176!3d50.10691741993941!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47ddfdf66c79b065%3A0xf4811e1670b9bbcb!2s1%20Rue%20de%20la%20Terrasse%2C%2080460%20Ault!5e0!3m2!1sfr!2sfr!4v1643465800019!5m2!1sfr!2sfr"
      ></iframe>
      <iframe
        className="iframeHome"
        width="100"
        height="100"
        src="https://www.youtube.com/embed/5daLpBeh9Vo"
        title="YouTube video player"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    </div>
  );
};

export default Home;
