import { doc, updateDoc } from "firebase/firestore";
import React, { useRef } from "react";

export default function StockForm({
   refreshData,
   collection,
   setData,
   options,
   data,
}) {
   const formRef = useRef();
   const inputs = useRef([]);

   const addInputs = (element) => {
      if (element && !inputs.current.includes(element)) {
         inputs.current.push(element);
      }
   };

   const handleForm = async (e) => {
      e.preventDefault();
      try {
         await updateDoc(doc(collection, data.docId), {
            items: [
               ...data.items,
               {
                  title: inputs.current[0].value,
                  state: inputs.current[1].value,
                  id: Date.now(),
               },
            ],
         });

         refreshData(collection, setData);
         inputs.current = [];
         formRef.current.reset();
      } catch (error) {
         console.log(error);
      }
   };

   return (
      <div>
         <form onSubmit={handleForm} ref={formRef}>
            <h5>Ajouter un produit</h5>
            <div className="flexRow">
               <div className="inputGroup">
                  <input
                     type="text"
                     name="product"
                     id="product"
                     placeholder="CSS"
                     ref={addInputs}
                     required
                  />
                  <label htmlFor="product">Produit</label>
               </div>
               <div className="inputGroup">
                  <select name="state" id="state" ref={addInputs}>
                     {options.map((option) => (
                        <option value={option} key={option}>
                           {option}
                        </option>
                     ))}
                  </select>
               </div>
            </div>
            <button>Ajouter</button>
         </form>
      </div>
   );
}
