import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import LoginForm from "../components/Connexion/LoginForm";
import Header from "../components/Header";
import { UserContext } from "../context/userContext";

const Connexion = () => {
   const { currentUser } = useContext(UserContext);

   if (currentUser) {
      return <Navigate to="/logged/home" />;
   }

   return (
      <>
         <Header />
         <div className="container connexion">
            <LoginForm />
            <div className="descriptionContainer">
               <h2>L'application</h2>
               <p>
                  DomathAult est une application créée pour faciliter la gestion
                  du partage d'un appartement à Ault.
               </p>
               <p>
                  L'appartement étant situé au bord de mer et principalement
                  occupé par les membres de la famille, l'application comprend
                  des fonctionnalitées tel que :
               </p>
               <ul>
                  <li>La météo locale</li>
                  <li>Horaires des marées du jour</li>
                  <li>La gestion des stocks (nourritures, linges, etc)</li>
                  <li>Guide d'appartement</li>
                  <li>Recommandations de visites</li>
                  <li>Calendrier de réservation</li>
               </ul>
               <p>
                  L'accès à cette application est privée. Pas d'inscription
                  possible.
               </p>
            </div>
         </div>
      </>
   );
};

export default Connexion;
