import React, { useContext, useRef } from "react";
import { UserContext } from "../../context/userContext";
import { DataContext } from "../../context/dataContext";
import { format } from "date-fns";
import fr from "date-fns/esm/locale/fr";

export default function ReviewsForm({
   collection,
   setData,
   refreshData,
   closeForm,
}) {
   const { currentUser } = useContext(UserContext);
   const { addANewDocument } = useContext(DataContext);
   const formRef = useRef();
   const inputs = useRef([]);

   const addInputs = (element) => {
      if (element && !inputs.current.includes(element)) {
         inputs.current.push(element);
      }
   };

   const dateTraduction = (date) => {
      return format(date, "d/MM/yyyy", { locale: fr });
   };

   const handleSubmit = (e) => {
      e.preventDefault();
      const docData = {
         id: Date.now(),
         title: inputs.current[0].value,
         city: inputs.current[1].value,
         url: inputs.current[2].value,
         type: inputs.current[3].value,
         description: inputs.current[4].value,
         emailAuthor: currentUser.email,
         date: dateTraduction(new Date()),
      };
      try {
         addANewDocument(collection, docData);
         refreshData(collection, setData);
         closeForm(false);
         inputs.current = [];
         formRef.current.reset();
      } catch (error) {
         console.log(error);
      }
   };

   return (
      <div className="overlay">
         <h1>Nouvelle recommandation</h1>
         <form onSubmit={handleSubmit} ref={formRef}>
            <div className="inputGroup">
               <input
                  type="text"
                  name="title"
                  id="title"
                  placeholder="CSS"
                  required
                  ref={addInputs}
               />
               <label htmlFor="title">Titre</label>
            </div>
            <div className="inputGroup">
               <input
                  type="text"
                  name="city"
                  id="city"
                  placeholder="CSS"
                  ref={addInputs}
                  required
               />
               <label htmlFor="city">Ville</label>
            </div>
            <div className="inputGroup">
               <input
                  type="text"
                  name="url"
                  id="url"
                  placeholder="CSS"
                  ref={addInputs}
               />
               <label htmlFor="url">
                  Lien Google Map <small>(optionelle)</small>
               </label>
            </div>
            <div className="inputGroup">
               <select name="type" id="type" ref={addInputs}>
                  <option value="fas fa-walking">Promenade</option>
                  <option value="fas fa-ticket-alt">Divertissement</option>
                  <option value="fas fa-utensils">Restaurant</option>
                  <option value="fas fa-shopping-cart">Magasin</option>
                  <option value="fas fa-store">Marché</option>
               </select>
            </div>
            <div className="inputGroup">
               <textarea
                  name="description"
                  id="description"
                  cols="30"
                  rows="10"
                  required
                  ref={addInputs}
                  placeholder="Description"
               ></textarea>
            </div>
            <button>Ajouter</button>
         </form>
      </div>
   );
}
