import React, { useContext, useRef } from "react";
import { UserContext } from "../../context/userContext";
import { DataContext } from "../../context/dataContext";
import { format } from "date-fns";
import fr from "date-fns/esm/locale/fr";

export default function CommentForm({
   collection,
   setData,
   refreshData,
   closeForm,
}) {
   const { currentUser } = useContext(UserContext);
   const { addANewDocument } = useContext(DataContext);
   const formRef = useRef();
   const inputs = useRef([]);

   const addInputs = (element) => {
      if (element && !inputs.current.includes(element)) {
         inputs.current.push(element);
      }
   };

   const dateTraduction = (date) => {
      return format(date, "d/MM/yyyy", { locale: fr });
   };

   const handleSubmit = (e) => {
      e.preventDefault();
      const docData = {
         id: Date.now(),
         comment: inputs.current[0].value,
         emailAuthor: currentUser.email,
         date: dateTraduction(new Date()),
      };
      try {
         addANewDocument(collection, docData);
         refreshData(collection, setData);
         closeForm(false);
         inputs.current = [];
         formRef.current.reset();
      } catch (error) {
         console.log(error);
      }
   };

   return (
      <div className="overlay">
         <h1>Nouveau commentaire</h1>
         <form onSubmit={handleSubmit} ref={formRef}>
            <div className="inputGroup">
               <textarea
                  name="description"
                  id="description"
                  cols="30"
                  rows="10"
                  required
                  ref={addInputs}
                  placeholder="Ton commentaire..."
               ></textarea>
            </div>
            <button>Publier</button>
         </form>
      </div>
   );
}
