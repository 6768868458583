import React, { useEffect, useState } from "react";
import { isSameDay } from "date-fns";

const CurrentWeather = ({ data }) => {
  const [currentWeather, setCurrentWeather] = useState(null);

  const getIcon = (icon) => `https://openweathermap.org/img/wn/${icon}@2x.png`;

  useEffect(() => {
    const currentW = data.list.filter((forecast) => {
      const today = new Date().getTime() + Math.abs(data.city.timezone * 1000);
      const forecastDate = new Date(forecast.dt * 1000);

      return isSameDay(today, forecastDate);
    });
    setCurrentWeather(currentW[0]);
  }, [data]);
  return (
    <div className="currentMeteo">
      <h2>Ault</h2>
      <h2>
        <span className="currentWeatherTemp">
          {Math.round(currentWeather?.main.temp).toString()}
        </span>
        <sup>°C</sup>
      </h2>
      <img
        src={getIcon(currentWeather?.weather[0].icon)}
        alt={currentWeather?.weather[0].main}
      />
      <h4>{currentWeather?.weather[0].description}</h4>
    </div>
  );
};

export default CurrentWeather;
