import React, { useContext } from "react";
import { Navigate, Outlet } from "react-router-dom";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import { UserContext } from "../../context/userContext";

const Logged = () => {
   const { currentUser, userData, logOut } = useContext(UserContext);

   if (!currentUser) {
      return <Navigate to="/" />;
   } else if (userData?.active === "false") {
      logOut();
   }

   return (
      <>
         <Header />
         <Outlet />
         <Footer />
      </>
   );
};

export default Logged;
