import React from "react";

const GuideCategory = ({ data }) => {
   return (
      <div className="categoryContainer">
         <h2 className="second">{data.title}</h2>
         <pre>{data.description}</pre>
         <img src={data.image} alt={data.title} />
      </div>
   );
};

export default GuideCategory;
