import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { UserContextProvider } from "./context/userContext";
import "./styles/styles.scss";
import DataContextProvider from "./context/dataContext";

ReactDOM.render(
   <React.StrictMode>
      <BrowserRouter>
         <UserContextProvider>
            <DataContextProvider>
               <App />
            </DataContextProvider>
         </UserContextProvider>
      </BrowserRouter>
   </React.StrictMode>,
   document.getElementById("root")
);

serviceWorkerRegistration.register();

reportWebVitals();
