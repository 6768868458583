import React, { useContext } from "react";
import { UserContext } from "../context/userContext";
import { NavLink } from "react-router-dom";

const Header = () => {
   const { currentUser, userData, logOut } = useContext(UserContext);

   return (
      <header>
         <h1 className="title">DomathAult</h1>
         {currentUser && userData?.role === "true" && (
            <NavLink
               to="/logged/admin/home"
               className={({ isActive }) =>
                  isActive ? "button active" : "button"
               }
            >
               Administration
            </NavLink>
         )}
      </header>
   );
};

export default Header;
