import { collection } from "firebase/firestore";
import React, { useContext, useEffect, useState } from "react";
import StockCategory from "../../components/Stock/StockCategory";

import { DataContext } from "../../context/dataContext";
import { firestore } from "../../firebase";

const Stock = () => {
   const [stock, setStock] = useState([]);
   const [loading, setLoading] = useState(false);
   const { getDocuments } = useContext(DataContext);

   const stockCollection = collection(firestore, "stock");

   useEffect(() => {
      getDocuments(stockCollection, setStock);
      setLoading(true);
      return () => {
         setStock(stock);
      };
   }, []);

   if (!loading) {
      return (
         <div className="container">
            <h1>Chargement...</h1>
         </div>
      );
   }
   return (
      <div className="container stock">
         {loading &&
            stock.map((category) => (
               <StockCategory
                  data={category}
                  key={category.docId}
                  refreshData={getDocuments}
                  setData={setStock}
                  collection={stockCollection}
               />
            ))}
      </div>
   );
};

export default Stock;
