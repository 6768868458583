import React, { useContext } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { UserContext } from "../../../context/userContext";

const Admin = () => {
   const { currentUser, userData } = useContext(UserContext);

   if (!currentUser) {
      return <Navigate to="/" />;
   } else if (!userData?.role) {
      return <Navigate to="/logged/home" />;
   }

   return (
      <>
         <Outlet />
      </>
   );
};

export default Admin;
