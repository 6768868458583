import React from "react";

export default function FloatingButton({ toggleForm, handleClick }) {
  return (
    <div
      className={toggleForm ? "floatingButton active" : "floatingButton"}
      onClick={handleClick}
    >
      {toggleForm ? (
        <i className="fas fa-times"></i>
      ) : (
        <i className="fas fa-plus"></i>
      )}
    </div>
  );
}
