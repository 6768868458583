import React from "react";

export default function StockItem({ data, onUpdate, onDelete, options }) {
   return (
      <div className="tableRow">
         <p>{data.title}</p>
         <select
            onChange={(e) => onUpdate(e, data.id)}
            name="state"
            value={data.state}
         >
            {options.map((option) => (
               <option value={option} key={option + data.id}>
                  {option}
               </option>
            ))}
         </select>
         <span>
            <i className="fas fa-times" onClick={() => onDelete(data.id)}></i>
         </span>
      </div>
   );
}
