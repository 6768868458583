import { collection } from "firebase/firestore";
import React, { useContext, useEffect, useState } from "react";
import GuideCategory from "../../components/Guide/GuideCategory";
import { DataContext } from "../../context/dataContext";
import { firestore } from "../../firebase";

const Guide = () => {
   const [data, setData] = useState([]);
   const [loading, setLoading] = useState(false);
   const { getDocuments } = useContext(DataContext);
   const guideCollection = collection(firestore, "guide");

   useEffect(() => {
      getDocuments(guideCollection, setData);
      setLoading(true);
      return () => {
         setData(data);
      };
   }, []);

   if (!loading) {
      return (
         <div className="container guide">
            <h1>En chargement...</h1>
         </div>
      );
   }

   return (
      <div className="container guide">
         <h1>Guide</h1>
         {loading &&
            data.map((guide) => (
               <GuideCategory data={guide} key={guide.docId} />
            ))}
      </div>
   );
};

export default Guide;
