import { doc, updateDoc } from "firebase/firestore";
import React, { useState } from "react";
import StockItem from "./StockItem";
import StockForm from "./StockForm";

const StockCategory = ({ data, collection, refreshData, setData }) => {
  const [toggleCategory, setToggleCategory] = useState(false);

  const handleToggle = () => {
    setToggleCategory(!toggleCategory);
  };

  const onDelete = async (id) => {
    const newData = data.items.filter((item) => item.id !== id);
    updateDoc(doc(collection, data.docId), {
      items: newData,
    });
    refreshData(collection, setData);
  };

  const onUpdate = (e, id) => {
    const otherItem = data.items.filter((item) => item.id !== id);
    const newItem = data.items.filter((item) => item.id === id);
    newItem[0].state = e.target.value;
    const newData = [...otherItem, ...newItem];
    updateDoc(doc(collection, data.docId), {
      items: newData,
    });
    refreshData(collection, setData);
  };

  return (
    <div
      className={
        toggleCategory ? "categoryContainer" : "categoryContainer close"
      }
    >
      <div className="containerHead" onClick={handleToggle}>
        <h2 className="second">{data.title}</h2>
        <div className="icon">
          {!toggleCategory ? (
            <i className="fas fa-chevron-down"></i>
          ) : (
            <i className="fas fa-chevron-up"></i>
          )}
        </div>
      </div>
      <div className="animated">
        <div className="flexTable">
          <div className="heading">
            <h4>Produit</h4>
            <h4>État</h4>
          </div>
          {data.items.map((item) => (
            <StockItem
              data={item}
              onUpdate={onUpdate}
              onDelete={onDelete}
              key={item.id}
              options={data.options}
            />
          ))}
        </div>
        <StockForm
          data={data}
          collection={collection}
          refreshData={refreshData}
          options={data.options}
          setData={setData}
        />
      </div>
    </div>
  );
};

export default StockCategory;
