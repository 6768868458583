import React, { useContext, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../context/userContext";

const LoginForm = () => {
   const { signIn, connexionError } = useContext(UserContext);

   const navigate = useNavigate();
   const inputs = useRef([]);

   const addInputs = (element) => {
      if (element && !inputs.current.includes(element)) {
         inputs.current.push(element);
      }
   };

   const handleForm = async (event) => {
      event.preventDefault();
      try {
         await signIn(inputs.current[0].value, inputs.current[1].value);
         navigate("/logged/home");
      } catch (e) {
         console.log(e.code);
      }
   };

   return (
      <form onSubmit={handleForm} className="loginForm">
         <h2>Connexion</h2>
         <div className="inputGroup">
            <input
               type="email"
               name="email"
               id="email"
               ref={addInputs}
               placeholder="css tricks"
               required
            />
            <label htmlFor="email" data-label="Adresse Mail">
               Adresse mail
            </label>
         </div>
         <div className="inputGroup">
            <input
               type="password"
               name="password"
               id="password"
               ref={addInputs}
               placeholder="css tricks"
               required
            />
            <label htmlFor="password" data-label="Mot de passe">
               Mot de passe
            </label>
         </div>
         <button type="submit">Connexion</button>
         {connexionError.length !== 0 && (
            <p className="validation">{connexionError}</p>
         )}
      </form>
   );
};

export default LoginForm;
