import React, { useEffect, useState } from "react";
import { format } from "date-fns";
import { fr } from "date-fns/locale";

const Forecasts = ({ data }) => {
   const [forecasts, setForecasts] = useState([]);

   const getIcon = (icon) => `https://openweathermap.org/img/wn/${icon}@2x.png`;

   useEffect(() => {
      const forecastsData = data.list.map((f) => {
         const dt = new Date(f.dt * 1000);
         return {
            date: dt,
            hour: dt.getHours(),
            temp: Math.round(f.main.temp),
            icon: getIcon(f.weather[0].icon),
            name: format(dt, "EEEE", { locale: fr }),
            description: f.weather[0].description,
         };
      });

      setForecasts(forecastsData);
   }, [data]);

   return (
      <div className="forcastsContainer">
         {forecasts.map((forecast) => (
            <div className="subContainer" key={forecast.date}>
               <h5 className="forecastDay">{forecast.name}</h5>
               <div className="forecast">
                  <h4>
                     <span className="forecastTemp">{forecast.temp}</span>
                     <sup>°C</sup>
                  </h4>
                  <img
                     src={forecast.icon}
                     alt={forecast.description}
                     width="50px"
                  />
                  <p>{forecast.hour}:00</p>
               </div>
            </div>
         ))}
      </div>
   );
};

export default Forecasts;
