import React from "react";
import { NavLink } from "react-router-dom";

const Menu = () => {
   return (
      <div className="homeMenuContainer">
         <NavLink to="/logged/guide" className="homeMenuButton boxDisplay">
            <i className="fas fa-question-circle"></i>
            <span>Guide</span>
         </NavLink>
         <NavLink to="/logged/calendar" className="homeMenuButton boxDisplay">
            <i className="fas fa-calendar-alt"></i>
            <span>Calendrier</span>
         </NavLink>
         <NavLink to="/logged/user" className="homeMenuButton myAccount">
            <i className="fas fa-user-circle"></i>
            <span>Mon compte</span>
         </NavLink>
      </div>
   );
};

export default Menu;
