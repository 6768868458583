import { collection } from "firebase/firestore";
import React, { useContext, useEffect, useState } from "react";
import Review from "../../components/Reviews/Review";
import FloatingButton from "../../components/FloatingButton";
import ReviewsForm from "../../components/Reviews/ReviewsForm";
import { DataContext } from "../../context/dataContext";
import { firestore } from "../../firebase";

const Reviews = () => {
  const [reviews, setReviews] = useState([]);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [toggleForm, setToggleForm] = useState(false);
  const { getDocuments } = useContext(DataContext);

  const usersCollection = collection(firestore, "users");
  const reviewsCollection = collection(firestore, "reviews");

  const handleToggleForm = () => {
    setToggleForm(!toggleForm);
  };

  useEffect(() => {
    getDocuments(reviewsCollection, setReviews);
    getDocuments(usersCollection, setUsers);
    setLoading(true);
  }, []);

  if (!loading) {
    return (
      <div className="container">
        <h1>En Chargement...</h1>
      </div>
    );
  }

  return (
    <div className="container reviews">
      <FloatingButton handleClick={handleToggleForm} toggleForm={toggleForm} />
      {reviews.map((review) => (
        <Review
          data={review}
          key={review.id}
          author={users}
          collection={reviewsCollection}
          refreshData={getDocuments}
          setData={setReviews}
        />
      ))}
      {toggleForm && (
        <ReviewsForm
          refreshData={getDocuments}
          setData={setReviews}
          collection={reviewsCollection}
          closeForm={setToggleForm}
        />
      )}
    </div>
  );
};

export default Reviews;
