import { doc, updateDoc } from "firebase/firestore";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import React, { useContext, useRef, useState } from "react";
import { Navigate, NavLink } from "react-router-dom";
import { DataContext } from "../../context/dataContext";
import { UserContext } from "../../context/userContext";
import { storage } from "../../firebase";

const AdminGuide = ({ data, collection, setData, refreshData }) => {
   const { signUp } = useContext(UserContext);
   const { addANewDocument } = useContext(DataContext);
   const [toggleForm, setToggleForm] = useState(false);
   const [validation, setValidation] = useState("");

   const formRef = useRef();
   const inputs = useRef([]);

   const addInputs = (element) => {
      if (element && !inputs.current.includes(element)) {
         inputs.current.push(element);
      }
   };

   const handleSubmitForm = async (e) => {
      e.preventDefault();
      console.log(inputs.current);

      const title = inputs.current[0].value;
      const description = inputs.current[1].value;
      const picture = inputs.current[2].files[0];

      try {
         const newGuide = {
            id: Date.now(),
            title: title,
            description: description,
            image: "",
         };

         if (picture) {
            if (picture) {
               const ext = picture.name.split(".");
               const getExt = ext[1];
               const storagePic = ref(storage, `guide/${title + "." + getExt}`);
               const uploadTask = uploadBytesResumable(storagePic, picture);
               uploadTask.on(
                  "state_changed",
                  (snapshot) => {
                     const prog = Math.round(
                        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                     );
                  },
                  (err) => {
                     console.log(err);
                  },
                  async () => {
                     getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                        newGuide.image = url;
                        addANewDocument(collection, newGuide);
                        refreshData(collection, setData);
                     });
                  }
               );
            } else {
               addANewDocument(collection, newGuide);
               refreshData(collection, setData);
            }
         }

         setToggleForm(false);
         inputs.current = [];
         formRef.current.reset();
      } catch (error) {
         console.log(error);
      }
   };

   const handleToggleForm = () => {
      setToggleForm(!toggleForm);
   };

   return (
      <div className="manageGuide">
         <h2>Gestions du Guide</h2>
         {data.map((guide) => {
            return (
               <div className="guide" key={guide.docId}>
                  <p className="name">
                     <span>{guide.title}</span>
                  </p>
                  <NavLink
                     to={"/logged/admin/edit-guide/" + guide.docId}
                     className="edit"
                  >
                     Modifier
                  </NavLink>
               </div>
            );
         })}
         <button className="add" onClick={handleToggleForm}>
            {toggleForm ? "Annuler" : "Nouveau guide"}
         </button>
         {toggleForm && (
            <form onSubmit={handleSubmitForm} ref={formRef}>
               <div className="inputGroup">
                  <input
                     type="text"
                     ref={addInputs}
                     name="title"
                     id="title"
                     placeholder="CSS tick"
                     required
                  />
                  <label htmlFor="title">Titre</label>
               </div>
               <div className="inputGroup">
                  <textarea
                     name="description"
                     id="description"
                     rows="10"
                     placeholder="Description"
                     required
                     ref={addInputs}
                  ></textarea>
               </div>
               <div className="inputGroup">
                  <input
                     type="file"
                     ref={addInputs}
                     name="picture"
                     id="picture"
                     placeholder="CSS tick"
                     required
                  />
                  <label htmlFor="picture">Photo</label>
               </div>
               <p>{validation}</p>
               <button>Ajouter le guide</button>
            </form>
         )}
      </div>
   );
};

export default AdminGuide;
