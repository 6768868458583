import { collection, doc, getDoc, updateDoc } from "firebase/firestore";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { firestore, storage } from "../../firebase";

const EditGuide = () => {
   const docId = useParams().id;
   const [currentGuide, setCurrentGuide] = useState([]);
   const [title, setTitle] = useState("");
   const [description, setDescription] = useState("");
   const guideCollection = collection(firestore, "guide");
   const navigate = useNavigate();

   const onChange = (e, setter) => {
      setter(e.target.value);
   };

   useEffect(() => {
      const getDocument = async () => {
         const document = await getDoc(doc(guideCollection, docId));
         setCurrentGuide(document.data());
         setTitle(document.data().title);
         setDescription(document.data().description);
      };

      getDocument();
   }, []);

   const formRef = useRef();
   const inputs = useRef([]);

   const addInputs = (element) => {
      if (element && !inputs.current.includes(element)) {
         inputs.current.push(element);
      }
   };

   const handleSubmit = async (e) => {
      e.preventDefault();

      const title = inputs.current[0].value;
      const description = inputs.current[1].value;
      const picture = inputs.current[2].files[0];

      const newData = {
         id: currentGuide.id,
         title: title,
         description: description,
         image: currentGuide.image,
      };

      if (picture) {
         const ext = picture.name.split(".");
         const getExt = ext[1];
         const storagePic = ref(storage, `guide/${title + "." + getExt}`);
         const uploadTask = uploadBytesResumable(storagePic, picture);
         uploadTask.on(
            "state_changed",
            (snapshot) => {
               const prog = Math.round(
                  (snapshot.bytesTransferred / snapshot.totalBytes) * 100
               );
            },
            (err) => {
               console.log(err);
            },
            async () => {
               getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                  newData.image = url;
                  updateDoc(doc(guideCollection, docId), newData);
               });
            }
         );
      } else {
         await updateDoc(doc(guideCollection, docId), newData);
      }
      inputs.current = [];
      formRef.current.reset();
      navigate("/logged/admin/home");
   };

   return (
      <div className="container editGuide">
         <form action="" onSubmit={handleSubmit} ref={formRef}>
            <div className="inputGroup">
               <input
                  type="text"
                  name="title"
                  id="title"
                  placeholder="CSS"
                  required
                  value={title}
                  onChange={(e) => onChange(e, setTitle)}
                  ref={addInputs}
               />
               <label htmlFor="title">Titre</label>
            </div>
            <div className="inputGroup">
               <textarea
                  name="description"
                  id="description"
                  rows="10"
                  onChange={(e) => onChange(e, setDescription)}
                  value={description}
                  placeholder="Description"
                  ref={addInputs}
                  required
               ></textarea>
            </div>
            <div className="pictureContainer">
               <img src={currentGuide.image} alt={currentGuide.title} />
               <div className="inputGroup">
                  <input
                     type="file"
                     name="picture"
                     id="picture"
                     ref={addInputs}
                  />
               </div>
            </div>
            <button type="submit">Mettre à jour</button>
         </form>
      </div>
   );
};

export default EditGuide;
