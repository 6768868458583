import { collection } from "firebase/firestore";
import React, { useContext, useEffect, useState } from "react";
import AdminGuide from "../../../components/Admin/AdminGuide";
import AdminUsers from "../../../components/Admin/AdminUsers";
import { DataContext } from "../../../context/dataContext";
import { firestore } from "../../../firebase";

const HomeAdmin = () => {
   const usersCollection = collection(firestore, "users");
   const guideCollection = collection(firestore, "guide");
   const { getDocuments } = useContext(DataContext);
   const [users, setUsers] = useState([]);
   const [guide, setGuide] = useState([]);
   const [loading, setLoading] = useState(false);

   useEffect(() => {
      getDocuments(usersCollection, setUsers);
      getDocuments(guideCollection, setGuide);
      setLoading(true);
   }, []);

   if (!loading) {
      return (
         <div className="container">
            <h1>En Chargement...</h1>
         </div>
      );
   }

   return (
      <div className="container">
         {loading && users && (
            <>
               <h1>Administration</h1>
               <AdminUsers
                  data={users}
                  setData={setUsers}
                  collection={usersCollection}
                  refreshData={getDocuments}
               />
            </>
         )}
         {loading && guide && (
            <AdminGuide
               data={guide}
               setData={setGuide}
               collection={guideCollection}
               refreshData={getDocuments}
            />
         )}
      </div>
   );
};

export default HomeAdmin;
