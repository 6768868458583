import { doc, updateDoc } from "firebase/firestore";
import React, { useContext, useRef, useState } from "react";
import { DataContext } from "../../context/dataContext";
import { UserContext } from "../../context/userContext";

const AdminUsers = ({ data, collection, setData, refreshData }) => {
   const { signUp } = useContext(UserContext);
   const { addANewDocument } = useContext(DataContext);
   const [toggleForm, setToggleForm] = useState(false);
   const [validation, setValidation] = useState("");

   const formRef = useRef();
   const inputs = useRef([]);

   const addInputs = (element) => {
      if (element && !inputs.current.includes(element)) {
         inputs.current.push(element);
      }
   };

   const handleSubmitForm = async (e) => {
      e.preventDefault();
      try {
         await signUp(inputs.current[2].value, "AppAult80!");
         const newUser = {
            id: Date.now(),
            lastname: inputs.current[0].value,
            firstname: inputs.current[1].value,
            email: inputs.current[2].value,
            picture:
               "https://firebasestorage.googleapis.com/v0/b/domatault.appspot.com/o/profile-pictures%2Fuser.png?alt=media&token=385fff23-3684-46a1-bd13-b309b4dfe071",
            active: "true",
            role: "false",
         };
         addANewDocument(collection, newUser);
         refreshData(collection, setData);
         setToggleForm(false);
         inputs.current = [];
         formRef.current.reset();
      } catch (error) {
         if (error.code === "auth/invalid-email") {
            setValidation("Format de l'adresse mail non valide !");
         }
         if (error.code === "auth/email-already-in-use") {
            setValidation("Adresse mail déjà utilisée !");
         }
      }
   };

   const handleToggleForm = () => {
      setToggleForm(!toggleForm);
   };

   // MANAGE USERS
   const handleToggleActive = async (id, activeValue) => {
      await updateDoc(doc(collection, id), {
         active: !activeValue,
      });
      refreshData(collection, setData);
   };

   return (
      <div className="manageUser">
         <h2>Gestions utilisateurs</h2>
         {data.map((user) => {
            return (
               <div
                  className={user.role === "true" ? "user admin" : "user"}
                  key={user.id}
               >
                  <p className="name">
                     <span>{user.firstname}</span>
                     <span>{user.lastname}</span>
                  </p>
                  {user.role === "true" ? (
                     <span>Administrateur</span>
                  ) : (
                     <span
                        className="activeUser"
                        onClick={() =>
                           handleToggleActive(user.docId, user.active)
                        }
                     >
                        {user.active ? "Actif" : "Inactif"}
                     </span>
                  )}
               </div>
            );
         })}
         <button className="add" onClick={handleToggleForm}>
            {toggleForm ? "Annuler" : "Nouvel utilisateur"}
         </button>
         {toggleForm && (
            <form onSubmit={handleSubmitForm} ref={formRef}>
               <div className="inputGroup">
                  <input
                     type="text"
                     ref={addInputs}
                     name="lastname"
                     id="lastname"
                     placeholder="CSS tick"
                     required
                  />
                  <label htmlFor="lastname">Nom de famille</label>
               </div>
               <div className="inputGroup">
                  <input
                     type="text"
                     ref={addInputs}
                     name="firstname"
                     id="firstname"
                     placeholder="CSS tick"
                     required
                  />
                  <label htmlFor="firstname">Prénom</label>
               </div>
               <div className="inputGroup">
                  <input
                     type="email"
                     ref={addInputs}
                     name="email"
                     id="email"
                     placeholder="CSS tick"
                     required
                  />
                  <label htmlFor="email">Adresse mail</label>
               </div>
               <p>{validation}</p>
               <button>Ajouter l'utilisateur</button>
            </form>
         )}
      </div>
   );
};

export default AdminUsers;
