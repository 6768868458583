import React, { createContext } from "react";
import { addDoc, getDocs, orderBy } from "firebase/firestore";

export const DataContext = createContext();

export default function DataContextProvider(props) {
   const addANewDocument = async (collection, object) => {
      try {
         await addDoc(collection, object);
      } catch (e) {
         console.error("Erreur d'ajout de document", e);
      }
   };

   const getDocuments = async (collections, setState) => {
      const querySnapshot = await getDocs(collections);
      let dataArray = [];
      querySnapshot.forEach((doc) => {
         const currentData = doc.data();
         currentData.docId = doc.id;
         dataArray.push(currentData);
      });
      dataArray.sort((a, b) => b.id - a.id);
      setState(dataArray);
   };

   return (
      <DataContext.Provider
         value={{
            addANewDocument,
            getDocuments,
         }}
      >
         {props.children}
      </DataContext.Provider>
   );
}
