import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import Connexion from "./pages/Connexion";
import CalendarCustom from "./pages/private/CalendarCustom";
import Logged from "./pages/private/Logged";
import Home from "./pages/private/Home";
import Stock from "./pages/private/Stock";
import Guide from "./pages/private/Guide";
import Reviews from "./pages/private/Reviews";
import Comments from "./pages/private/Comments";
import User from "./pages/private/User";
import NotFound from "./pages/NotFound";
import Admin from "./pages/private/admin/Admin";
import HomeAdmin from "./pages/private/admin/HomeAdmin";
import EditGuide from "./components/Admin/EditGuide";

function App() {
   return (
      <Routes>
         <Route path="/" element={<Connexion />} />
         <Route path="/logged" element={<Logged />}>
            <Route path="/logged/home" element={<Home />} />
            <Route path="/logged/stock" element={<Stock />} />
            <Route path="/logged/guide" element={<Guide />} />
            <Route path="/logged/reviews" element={<Reviews />} />
            <Route path="/logged/comments" element={<Comments />} />
            <Route path="/logged/calendar" element={<CalendarCustom />} />
            <Route path="/logged/user" element={<User />} />
            <Route path="/logged/admin" element={<Admin />}>
               <Route path="/logged/admin/home" element={<HomeAdmin />} />
               <Route
                  path="/logged/admin/edit-guide/:id"
                  element={<EditGuide />}
               />
            </Route>
         </Route>
         <Route path="*" element={<Navigate to="/" />} />
      </Routes>
   );
}

export default App;
