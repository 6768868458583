import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { DataContext } from "../../context/dataContext";
import CurrentWeather from "./Meteo/CurrentWeather";
import Forecasts from "./Meteo/Forecasts";

const Meteo = () => {
  const { isLoading } = useContext(DataContext);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState();

  const API_URL = `https://api.openweathermap.org/data/2.5/forecast?q=Ault&appid=879bc9261d7a86cfa9472a5f55c9f65a&lang=fr&units=metric`;

  useEffect(() => {
    getWeather();

    return setData(data);
  }, []);

  const getWeather = async () => {
    try {
      const response = await axios.get(API_URL);

      setData(response.data);
      setLoading(false);
    } catch (e) {
      console.error("Error in getWeather", e);
    }
  };

  if (loading) {
    return (
      <div>
        <h1>Chargement...</h1>
      </div>
    );
  }

  return (
    <div className="meteoContainer">
      <div className="iframe">
        <iframe
          title="tideSea"
          src="https://horloge.maree.frbateaux.net/ws12?col=1"
          scrolling="no"
        ></iframe>
        {/* <div class="cacheBottom"></div> */}
      </div>
      <CurrentWeather data={data} />
      <Forecasts data={data} />
    </div>
  );
};

export default Meteo;
