import { deleteDoc, doc } from "firebase/firestore";
import React, { useContext } from "react";
import { UserContext } from "../../context/userContext";

export default function Comment({
   author,
   data,
   setData,
   refreshData,
   collection,
}) {
   const { currentUser } = useContext(UserContext);

   const onDelete = async (docId) => {
      const confirmDelete = window.confirm(
         "Es-tu sûr de vouloir supprimer ce commentaire ?"
      );

      if (confirmDelete) {
         await deleteDoc(doc(collection, docId));
         refreshData(collection, setData);
      }
   };

   return (
      <div className="comment resetMargin" key={"comment" + data.id}>
         {author.map(
            (user) =>
               user.email === data.emailAuthor && (
                  <div className="author" key={user.id}>
                     <img
                        src={user.picture}
                        alt={user.firstname + " " + user.lastname}
                     />
                     <h4>
                        {user.firstname} {user.lastname}
                        <span>{data.date}</span>
                     </h4>
                     {currentUser.email === data.emailAuthor && (
                        <span
                           className="delete"
                           onClick={() => onDelete(data.docId)}
                        >
                           <i className="fas fa-times"></i>
                        </span>
                     )}
                  </div>
               )
         )}
         <div className="content">
            <pre>{data.comment}</pre>
         </div>
      </div>
   );
}
