import { createContext, useState, useEffect } from "react";
import {
   signInWithEmailAndPassword,
   onAuthStateChanged,
   createUserWithEmailAndPassword,
   signOut,
} from "firebase/auth";
import { useNavigate } from "react-router-dom";

import { auth } from "../firebase";
import { firestore } from "../firebase";
import { collection, getDocs } from "firebase/firestore";

export const UserContext = createContext();

export function UserContextProvider(props) {
   const usersCollection = collection(firestore, "users");
   const navigate = useNavigate();

   const signUp = (email, password) => {
      createUserWithEmailAndPassword(auth, email, password);
   };

   const signIn = (email, password) => {
      signInWithEmailAndPassword(auth, email, password)
         .then((userCredential) => {
            getUserData(email);
            setCurrentUser(userCredential.user);
            setConnexionError("");
         })
         .catch((e) => {
            switch (e.code) {
               case "auth/wrong-password":
                  setConnexionError("Mauvais mot de passe.");
                  break;
               case "auth/user-not-found":
                  setConnexionError("Adresse mail inconnu.");
                  break;
               default:
                  setConnexionError("Une erreur inconnu est survenu");
                  break;
            }
         });
   };

   const logOut = async () => {
      await signOut(auth)
         .then(() => {
            navigate("/");
            setCurrentUser(null);
            emptyUserCache(userData);
         })
         .catch((e) => {
            alert(
               "Pour différente raisons. Nous n'avons pas pu vous deconnecter. \n Merci de vérifier votre connexion internet. "
            );
            console.log(e);
         });
   };

   const emptyUserCache = (object) => {
      for (const prop in object) {
         localStorage.removeItem(prop);
      }
   };

   const [currentUser, setCurrentUser] = useState(null);
   const [loadingData, setLoadingData] = useState(true);
   const [userData, setUserData] = useState(null);
   const [connexionError, setConnexionError] = useState("");

   const getUserData = async (email) => {
      const querySnapshot = await getDocs(usersCollection);

      querySnapshot.forEach((doc) => {
         const currentUserData = doc.data();
         currentUserData.docId = doc.id;
         if (currentUserData.email === email) {
            setUserData(currentUserData);
            for (const prop in currentUserData) {
               localStorage.setItem(prop, currentUserData[prop]);
            }
         }
      });
   };

   useEffect(() => {
      const unsubscribe = onAuthStateChanged(auth, (currUser) => {
         if (localStorage.email && localStorage.email !== currUser.email) {
            setLoadingData(false);
            return;
         }
         setCurrentUser(currUser);
         setLoadingData(false);
      });
      if (localStorage.email) {
         setUserData(localStorage);
      }
      setLoadingData(false);

      return unsubscribe;
   }, []);

   return (
      <UserContext.Provider
         value={{
            userData,
            setUserData,
            currentUser,
            signIn,
            signUp,
            logOut,
            setCurrentUser,
            connexionError,
         }}
      >
         {!loadingData && props.children}
      </UserContext.Provider>
   );
}
