import { format } from "date-fns";
import fr from "date-fns/esm/locale/fr";
import React, { useContext, useEffect, useState } from "react";
import Calendar from "react-calendar";
import { DataContext } from "../../context/dataContext";
import "react-calendar/dist/Calendar.css";
import { collection, deleteDoc, doc, updateDoc } from "firebase/firestore";
import { firestore } from "../../firebase";
import { UserContext } from "../../context/userContext";

const CalendarCustom = () => {
   const [users, setUsers] = useState([]);
   const [calendarData, setCalendarData] = useState([]);
   const [loading, setLoading] = useState(false);
   const [date, setDate] = useState(new Date());
   const [multipleDate, setMultipleDate] = useState(true);
   const { getDocuments, addANewDocument } = useContext(DataContext);
   const { currentUser, userData } = useContext(UserContext);

   const usersCollection = collection(firestore, "users");
   const calendarCollection = collection(firestore, "calendar");

   useEffect(() => {
      getDocuments(usersCollection, setUsers);
      getDocuments(calendarCollection, setCalendarData);
      setLoading(true);
   }, []);

   const dateTraduction = (date) => {
      return format(date, "EEEE d MMMM yyyy", { locale: fr });
   };

   const validation = (newDate) => {
      try {
         if (newDate.length === undefined) {
            newDate = [dateTraduction(newDate)];
         }
         if (newDate.length === 2) {
            const arrDate = [];
            newDate.map((date) => arrDate.push(dateTraduction(date)));
            newDate = arrDate;
         }
         const newData = {
            id: Date.now(),
            emailAuthor: currentUser.email,
            status: "wait",
            date: newDate,
         };
         addANewDocument(calendarCollection, newData);
         getDocuments(calendarCollection, setCalendarData);
      } catch (e) {
         console.log(e);
      }
   };

   const onChange = (newDate) => {
      setDate(newDate);
   };
   const toggleMultiple = () => {
      setMultipleDate(!multipleDate);
   };

   const onUpdateStatus = async (docId, value) => {
      await updateDoc(doc(calendarCollection, docId), {
         status: value,
      });
      getDocuments(calendarCollection, setCalendarData);
   };

   const onDelete = async (docId) => {
      const confirm = window.confirm(
         "Es-tu sûr de vouloir supprimer ta demande ?"
      );

      if (confirm) {
         await deleteDoc(doc(calendarCollection, docId));
         getDocuments(calendarCollection, setCalendarData);
      }
   };

   if (!loading) {
      return (
         <div className="container">
            <h1>En Chargement...</h1>
         </div>
      );
   }
   return (
      <div className="container calendar">
         <h1>Réservation</h1>
         <p className="info">
            Choisis ici une ou plusieurs date. <br />
            Le mode par défault est : <strong>Plusieurs Date</strong>, tu peux
            le visualiser avec le bouton violet. <br />
            Pour changer ce mode, tape sur ce bouton.
         </p>
         <div>
            {multipleDate ? (
               <Calendar
                  onChange={onChange}
                  selectRange={true}
                  value={date}
                  locale={"fr-FR"}
               />
            ) : (
               <Calendar onChange={onChange} value={date} locale={"fr-FR"} />
            )}
         </div>
         <div className="btnContainer">
            <button onClick={() => validation(date)}>Valider</button>
            <button onClick={toggleMultiple}>
               {multipleDate ? "Plusieurs Date" : "Une date"}
            </button>
         </div>
         {calendarData && (
            <div className="dateRequest">
               <h2>Date demandées</h2>
               {calendarData.map((requestDate) => {
                  return (
                     <div
                        className={"request " + requestDate.status}
                        key={requestDate.id}
                     >
                        {users.map(
                           (user) =>
                              user.email === requestDate.emailAuthor && (
                                 <div className="author" key={user.id}>
                                    <img
                                       src={user.picture}
                                       alt={
                                          user.firstname + " " + user.lastname
                                       }
                                    />
                                    <h4>
                                       {user.firstname} {user.lastname}
                                    </h4>
                                    {(requestDate.status === "wait" ||
                                       requestDate.status === "refused" ||
                                       userData.role === "true") &&
                                       (userData.role === "true" ||
                                          currentUser.email ===
                                             requestDate.emailAuthor) && (
                                          <span
                                             className="delete"
                                             onClick={() =>
                                                onDelete(requestDate.docId)
                                             }
                                          >
                                             <i className="fas fa-times"></i>
                                          </span>
                                       )}
                                 </div>
                              )
                        )}
                        {requestDate.date.length === 1 && (
                           <p className="date">Le : {requestDate.date[0]}</p>
                        )}
                        {requestDate.date.length === 2 && (
                           <>
                              <p className="date">Du : {requestDate.date[0]}</p>
                              <p className="date">Au : {requestDate.date[1]}</p>
                           </>
                        )}
                        {(userData.role === true ||
                           userData.role === "true") && (
                           <div className="dateAdmin">
                              {requestDate.status !== "accepted" && (
                                 <button
                                    className="accepted"
                                    onClick={() =>
                                       onUpdateStatus(
                                          requestDate.docId,
                                          "accepted"
                                       )
                                    }
                                 >
                                    Accepter
                                 </button>
                              )}
                              {requestDate.status !== "wait" && (
                                 <button
                                    className="wait"
                                    onClick={() =>
                                       onUpdateStatus(requestDate.docId, "wait")
                                    }
                                 >
                                    En attente
                                 </button>
                              )}
                              {requestDate.status !== "refused" && (
                                 <button
                                    className="refused"
                                    onClick={() =>
                                       onUpdateStatus(
                                          requestDate.docId,
                                          "refused"
                                       )
                                    }
                                 >
                                    Refuser
                                 </button>
                              )}
                           </div>
                        )}
                     </div>
                  );
               })}
            </div>
         )}
      </div>
   );
};

export default CalendarCustom;
