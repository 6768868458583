import { deleteDoc, doc } from "firebase/firestore";
import React, { useContext } from "react";
import { UserContext } from "../../context/userContext";

export default function Review({
   data,
   author,
   collection,
   refreshData,
   setData,
}) {
   const { currentUser } = useContext(UserContext);

   const onDelete = async (docId) => {
      const userConfirm = window.confirm(
         "Es-tu sûr de vouloir supprimer cette recommandations ?"
      );

      if (userConfirm) {
         await deleteDoc(doc(collection, docId));
         refreshData(collection, setData);
      }
   };

   return (
      <div className="review" key={data.id}>
         <div className="badge">
            <i className={data.type}></i>
         </div>
         {author.map((user) => {
            return (
               user.email === data.emailAuthor && (
                  <div className="author" key={user.id}>
                     <img
                        src={user.picture}
                        alt={user.firstname + " " + user.lastname}
                     />
                     <h4>
                        {user.firstname} {user.lastname}
                        <span>{data.date}</span>
                     </h4>
                     {currentUser.email === data.emailAuthor && (
                        <span
                           className="delete"
                           onClick={() => onDelete(data.docId)}
                        >
                           <i className="fas fa-times"></i>
                        </span>
                     )}
                  </div>
               )
            );
         })}
         <div className="content">
            <h5>{data.title}</h5>
            {data.city.length !== 0 && <h6>{data.city}</h6>}
            <pre>{data.description}</pre>
            {data.url.length !== 0 && (
               <a href={data.url} target="_blank" rel="noopener noreferrer">
                  <i className="fas fa-map-marker-alt"></i>
                  Itinéraire
               </a>
            )}
         </div>
      </div>
   );
}
