import React from "react";
import { NavLink } from "react-router-dom";

const Footer = () => {
   return (
      <footer>
         <NavLink
            exact="true"
            to="/logged/home"
            className={({ isActive }) => (isActive ? "active" : "inactive")}
         >
            <i className="fas fa-home"></i>
            <span>Accueil</span>
         </NavLink>
         <NavLink
            exact="true"
            to="/logged/stock"
            className={({ isActive }) => (isActive ? "active" : "inactive")}
         >
            <i className="fas fa-boxes"></i>
            <span>Stock</span>
         </NavLink>
         <NavLink
            exact="true"
            to="/logged/reviews"
            className={({ isActive }) => (isActive ? "active" : "inactive")}
         >
            <i className="fas fa-map-signs"></i>
            <span>Recommandations</span>
         </NavLink>
         <NavLink
            exact="true"
            to="/logged/comments"
            className={({ isActive }) => (isActive ? "active" : "inactive")}
         >
            <i className="far fa-comment"></i>
            <span>Commentaires</span>
         </NavLink>
      </footer>
   );
};

export default Footer;
