import { collection } from "firebase/firestore";
import React, { useContext, useEffect, useState } from "react";
import Comment from "../../components/Comments/Comment";
import CommentForm from "../../components/Comments/CommentForm";
import FloatingButton from "../../components/FloatingButton";
import { DataContext } from "../../context/dataContext";
import { firestore } from "../../firebase";

const Comments = () => {
   const [comments, setComments] = useState([]);
   const [users, setUsers] = useState([]);
   const [toggleForm, setToggleForm] = useState(false);
   const { getDocuments } = useContext(DataContext);
   const commentsCollection = collection(firestore, "comments");
   const usersCollection = collection(firestore, "users");

   useEffect(() => {
      getDocuments(commentsCollection, setComments);
      getDocuments(usersCollection, setUsers);
   }, []);

   const handleToggleForm = () => {
      setToggleForm(!toggleForm);
   };

   return (
      <div className="container comments">
         <FloatingButton
            handleClick={handleToggleForm}
            toggleForm={toggleForm}
         />
         {comments.map((comment) => (
            <Comment
               data={comment}
               author={users}
               refreshData={getDocuments}
               setData={setComments}
               collection={commentsCollection}
               key={comment.id}
            />
         ))}
         {toggleForm && (
            <CommentForm
               closeForm={setToggleForm}
               collection={commentsCollection}
               setData={setComments}
               refreshData={getDocuments}
            />
         )}
      </div>
   );
};

export default Comments;
