import React, { useRef, useContext, useEffect, useState } from "react";
import { firestore, storage } from "../../firebase";
import { DataContext } from "../../context/dataContext";
import { UserContext } from "../../context/userContext";
import { collection, doc, updateDoc } from "firebase/firestore";
import { updatePassword } from "firebase/auth";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";

const User = () => {
   const { getDocuments } = useContext(DataContext);
   const { logOut, currentUser } = useContext(UserContext);
   const [loading, setLoading] = useState(false);
   const [userData, setUserData] = useState([]);
   const [validation, setValidation] = useState("");
   const usersCollection = collection(firestore, "users");

   useEffect(() => {
      getDocuments(usersCollection, setUserData);
      setLoading(true);
   }, []);

   const formRef = useRef();
   const inputs = useRef([]);

   const addInputs = (element) => {
      if (element && !inputs.current.includes(element)) {
         inputs.current.push(element);
      }
   };

   const handleSubmit = async (e) => {
      const searchCurrentUserData = userData.filter(
         (user) => user.email === currentUser.email
      );
      const currentUserData = searchCurrentUserData[0];
      e.preventDefault();
      const lastname = inputs.current[0].value;
      const firstname = inputs.current[1].value;
      const password = inputs.current[2].value;
      const repeatPassword = inputs.current[3].value;
      const picture = inputs.current[4].files[0];

      const newDataUser = {
         id: currentUserData.id,
         role: currentUserData.role,
         active: currentUserData.active,
         firstname: currentUserData.firstname,
         lastname: currentUserData.lastname,
         picture: currentUserData.picture,
         email: currentUserData.email,
      };
      if (lastname.length !== 0) {
         newDataUser.lastname = lastname;
      }
      if (firstname.length !== 0) {
         newDataUser.firstname = firstname;
      }
      if (password.length !== 0) {
         if (repeatPassword.length !== 0) {
            if (password === repeatPassword) {
               if (password.length >= 6) {
                  updatePassword(currentUser, password)
                     .then(() => {
                        setValidation("Mot de passe mis à jour");
                     })
                     .catch((error) =>
                        setValidation(
                           "Déconnecte toi et reconnecte toi pour changer ton mot de passe"
                        )
                     );
               } else {
                  setValidation(
                     "Le mot de passe doit faire plus de 6 caractères"
                  );
               }
            } else {
               setValidation("Les mots de passes ne sont pas identiques");
            }
         } else {
            setValidation("Il faut répéter le mot de passe.");
         }
      }
      if (picture) {
         const ext = picture.name.split(".");
         const getExt = ext[1];
         const storagePic = ref(
            storage,
            `profile-pictures/${
               currentUserData.lastname +
               "-" +
               currentUserData.firstname +
               "." +
               getExt
            }`
         );
         const uploadTask = uploadBytesResumable(storagePic, picture);
         uploadTask.on(
            "state_changed",
            (snapshot) => {
               const prog = Math.round(
                  (snapshot.bytesTransferred / snapshot.totalBytes) * 100
               );
            },
            (err) => {
               console.log(err);
            },
            async () => {
               getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                  newDataUser.picture = url;
                  updateDoc(
                     doc(usersCollection, currentUserData.docId),
                     newDataUser
                  );
                  getDocuments(usersCollection, setUserData);
               });
            }
         );
      } else {
         await updateDoc(
            doc(usersCollection, currentUserData.docId),
            newDataUser
         );
         getDocuments(usersCollection, setUserData);
      }
      inputs.current = [];
      formRef.current.reset();
   };

   if (!loading) {
      return (
         <div className="container">
            <h1>En Chargement...</h1>
         </div>
      );
   }

   return (
      <div className="container user">
         <h1>Mon compte</h1>
         {userData.length !== 0 &&
            userData.map(
               (user) =>
                  (user = user.email === currentUser.email && (
                     <form
                        onSubmit={handleSubmit}
                        ref={formRef}
                        key={user.docId}
                     >
                        <div className="inputGroup">
                           <input
                              type="text"
                              name="lastname"
                              id="lastname"
                              ref={addInputs}
                              className="ph-visible"
                              placeholder={user.lastname}
                           />
                           <label htmlFor="lastname">Nom de famille</label>
                        </div>
                        <div className="inputGroup">
                           <input
                              type="text"
                              name="firstname"
                              id="firstname"
                              ref={addInputs}
                              className="ph-visible"
                              placeholder={user.firstname}
                           />
                           <label htmlFor="firstname">Prénom</label>
                        </div>
                        <div className="inputGroup">
                           <input
                              type="password"
                              name="password"
                              id="password"
                              ref={addInputs}
                              placeholder="css"
                           />
                           <label htmlFor="password">Mot de passe</label>
                        </div>
                        <div className="inputGroup">
                           <input
                              type="password"
                              name="rpPassword"
                              id="rpPassword"
                              ref={addInputs}
                              placeholder="css"
                           />
                           <label htmlFor="rpPassword">
                              Repété le mot de passe
                           </label>
                        </div>
                        <div className="pictureContainer">
                           <img src={user.picture} alt={user.lastname} />
                           <div className="inputGroup">
                              <input
                                 type="file"
                                 name="picture"
                                 id="picture"
                                 ref={addInputs}
                              />
                              <label htmlFor="picture">Avatar</label>
                           </div>
                        </div>
                        <h5>{validation}</h5>
                        <button>Modifier</button>
                     </form>
                  ))
            )}
         <button onClick={logOut} className="logOut">
            Deconnexion
         </button>
      </div>
   );
};

export default User;
